import { Contact } from "./contact";

export enum AppSensitivity {
  Pas_de_contenu_sensible = "Pas de contenu sensible",
  Contenu_sensible_à_protéger = "Contenu sensible à protéger",
  Contenu_sensible_autorisé = "Contenu sensible autorisé"
}

export enum AppStatus {
  Déployée = "Déployée",
  En_Cours = "En Cours",
  Planifiée = "Planifiée"
}

export class App {
  appName: string;
  url: string;
  documentationUrl: string;
  shortDescription_fr: string;
  shortDescription_en: string;
  description_fr: string;
  description_en: string;
  contact: Contact[];
  category: string;
  processesIds: string[];
  status: AppStatus;
  roles: string[];
  isDevelopedByCEA: boolean;
  sensitivity: AppSensitivity;
  matrixElementRoomUrl: string;
  notes: string;

  constructor(
    appName: string, url: string,
    documentationUrl: string,
    shortDescription_fr: string, shortDescription_en: string,
    description_fr: string, description_en: string,
    contact: Contact[], category: string,
    processes: string[], status: AppStatus,
    roles: string[],
    isDevelopedByCEA: boolean,
    sensitivity: AppSensitivity,
    matrixElementRoomUrl: string,
    notes: string,
  ) {
    this.appName = appName;
    this.url = url;
    this.documentationUrl = documentationUrl;
    this.shortDescription_fr = shortDescription_fr;
    this.shortDescription_en = shortDescription_en;
    this.description_fr = description_fr;
    this.description_en = description_en;
    this.contact = contact;
    this.category = category;
    this.processesIds = processes;
    this.status = status;
    this.roles = roles;
    this.isDevelopedByCEA = isDevelopedByCEA;
    this.sensitivity = sensitivity;
    this.matrixElementRoomUrl = matrixElementRoomUrl;
    this.notes = notes;
  }
}