import { Tooltip } from "react-tooltip";

export default function CEABanner() {
  const randomIdTooltip = Math.random(); // otherwise hovering one Banner makes all tooltips appear
  return (
    <div className="absolute top-4 right-2 rotate-45">
      <div className="rounded-sm text-white py-1 px-2 bg-red-500"
        data-tooltip-id={randomIdTooltip.toString()}
        data-tooltip-content={'Outil développé au CEA'}
      >
        CEA
      </div>
      <Tooltip
        id={randomIdTooltip.toString()}
        place='left'
      />
    </div>);
}