import { AppSensitivity } from "./app";

export class FilteringSensitivity {
  sensitivity: AppSensitivity;
  selected: boolean;

  constructor(sensitivity: AppSensitivity, selected: boolean) {
    this.sensitivity = sensitivity;
    this.selected = selected;
  }
}
