import { App } from "../../../../models/app";
import Actions from "./actions";
import CEABanner from "./ceaBanner";
import StatusOverlay from "./statusOverlay";
import Top from "./top";

export default function Card(app: App, logo: string | undefined, showMoreInfo: Function) {
  return (
    <div
      key={app.appName}
      className="relative flex flex-col justify-between mx-4 my-2 px-6 py-4 bg-slate-50 drop-shadow-md rounded-lg w-72 max-w-72 h-60 max-h-60"
    >
      {StatusOverlay(app)}
      {Top(app, logo)}
      <div className="text-black my-2 min-h-20 max-h-20 text-clip overflow-y-auto">
        {app.shortDescription_fr}
      </div>
      {Actions(app, showMoreInfo)}
      {app.isDevelopedByCEA === true ? <CEABanner /> : null}
    </div>
  )
}