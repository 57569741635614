import Keycloak, { KeycloakInitOptions } from "keycloak-js";
import { createContext, useEffect, useState } from "react";

const skipAuth = window.location.href === "http://localhost:3000/"; // for DEV purposes

/**
 * KeycloakInitOptions configures the Keycloak client.
 */
const keycloakInitOptions: KeycloakInitOptions = {
  // Configure that Keycloak will check if a user is already authenticated (when opening the app or reloading the page). If not authenticated the user will be send to the login form. If already authenticated the webapp will open.
  onLoad: "login-required", checkLoginIframe: false, pkceMethod: "S256",
};

// Create the Keycloak client instance
const keycloak = new Keycloak();

/**
 * AuthContextValues defines the structure for the default values of the {@link AuthContext}.
 */
interface AuthContextValues {
  /**
   * Whether or not a user is currently authenticated
   */
  isAuthenticated: boolean;
  /**
   * The name of the authenticated user
   */
  username: string;
  /**
   * Function to initiate the logout
   */
  logout: () => void;
}

/**
 * Default values for the {@link AuthContext}
 */
const defaultAuthContextValues: AuthContextValues = {
  isAuthenticated: false,
  username: "",
  logout: () => { },
};

/**
 * Create the AuthContext using the default values.
 */
export const AuthContext = createContext<AuthContextValues>(
  defaultAuthContextValues
);

/**
 * The props that must be passed to create the {@link AuthContextProvider}.
 */
interface AuthContextProviderProps {
  /**
   * The elements wrapped by the auth context.
   */
  children: JSX.Element;
}

/**
 * AuthContextProvider is responsible for managing the authentication state of the current user.
 *
 * @param props
 */
const AuthContextProvider = (props: AuthContextProviderProps) => {
  // Create the local state in which we will keep track if a user is authenticated
  const [isAuthenticated, setAuthenticated] = useState<boolean>(skipAuth);
  // Local state that will contain the users name once it is loaded
  const [username, setUsername] = useState<string>("");

  // Effect used to initialize the Keycloak client. It has no dependencies so it is only rendered when the app is (re-)loaded.
  useEffect(() => {
    if (skipAuth) return; // for DEV purposes

    /**
     * Initialize the Keycloak instance
     */
    async function initializeKeycloak() {
      try {
        const isAuthenticatedResponse = await keycloak.init(
          keycloakInitOptions
        );
        // If the authentication was not successfull the user is send back to the Keycloak login form
        if (!isAuthenticatedResponse) {
          keycloak.login();
        }
        // If we get here the user is authenticated and we can update the state accordingly
        setAuthenticated(true);
      } catch (error) {
        console.error(error);
        setAuthenticated(false);
      }
    }
    initializeKeycloak();
  }, []);

  // This effect loads the users profile in order to extract the username
  useEffect(() => {
    if (skipAuth) return; // for DEV purposes

    /**
     * Load the profile for of the user from Keycloak
     */
    async function loadProfile() {
      const profile = await keycloak.loadUserProfile();
      if (profile.firstName && profile.lastName) {
        setUsername(profile.firstName + " " + profile.lastName.toUpperCase());
      }
    }

    // Only load the profile if a user is authenticated
    if (isAuthenticated) {
      loadProfile();
    }
  }, [isAuthenticated]);

  /**
   * Initiate the logout
   */
  const logout = () => {
    keycloak.logout();
  };

  // Setup the context provider
  return (
    <AuthContext.Provider
      value={{ isAuthenticated, username, logout }}
    >
      {props.children}
    </AuthContext.Provider>
  );
};

export default AuthContextProvider;