import { Tooltip } from "react-tooltip";
import { doesAppHaveProcessOfTypeLogiciel, doesAppHaveProcessOfTypeSysteme, getProcessNameFromID } from "../../../../lib/process";
import { App } from "../../../../models/app";

export default function Top(app: App, logo: string | undefined) {

  /**
   * return a div (pill component) 
   */
  const renderProcessPill = (color: string) => {
    const appProcessesIdsSysteme = app.processesIds.filter((n) => n.startsWith('6.'));
    const appProcessesIdsLogiciel = app.processesIds.filter((n) => n.startsWith('7.'));
    const joinProcessesNames = (appProcessesIds: string[]) => {
      return <div className={`flex flex-col`}>
        {appProcessesIds.map((id) => (<span key={getProcessNameFromID(id)}>{getProcessNameFromID(id)}</span>))}
      </div>
    }
    return (
      <>
        <div
          className={`mx-1 my-1 h-6 w-6 rounded-full bg-${color}-500 whitespace-nowrap`}
          data-tooltip-id={"process-tooltip" + app.appName + color}
        />
        <Tooltip id={"process-tooltip" + app.appName + color} place='bottom' render={color === "green" ? () => joinProcessesNames(appProcessesIdsSysteme) : () => joinProcessesNames(appProcessesIdsLogiciel)} />
      </>)
      ;
  }

  return (
    <div className="flex flex-row">
      <div className="mr-2 flex items-center">
        <img
          src={logo}
          alt=""
          width={60}
          height={60}
        />
      </div>
      <div className="flex flex-col">
        <div className="px-2 text-black font-bold text-lg">
          {app.appName}
        </div>
        <div className="flex flex-row flex-wrap max-w-full min-h-20 max-h-20 text-clip overflow-y-auto">
          {doesAppHaveProcessOfTypeSysteme(app.processesIds) ? renderProcessPill("green") : null}
          {doesAppHaveProcessOfTypeLogiciel(app.processesIds) ? renderProcessPill("blue") : null}
        </div>
      </div>
    </div>
  )
}