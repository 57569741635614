
export default function SearchBar(searchInput: string, setSearchInput: Function) {
  const handleChange = (e: React.FormEvent<HTMLInputElement>) => {
    e.preventDefault();
    setSearchInput(e.currentTarget.value);
  };

  return (
    <div className="flex justify-center">
      <div className="flex flex-row bg-white py-2 my-4 items-center hover:outline hover:outline-1 hover:outline-black rounded-lg">
        <img src="/icons/search-icon.png" className="h-10 w-10 p-2" alt="Search icon" />
        <input
          className="ml-2 py-2 w-72 focus:outline-0"
          type="text"
          placeholder="Ex: Tuleap, modélisation, DevOps, ..."
          onChange={handleChange}
          value={searchInput}
          width={250} />
      </div>
    </div>
  )
}