import { useState } from "react";
import { App, AppSensitivity } from "../../../models/app";
import { FilteringProcess } from "../../../models/filteringProcess";
import { FilteringSensitivity } from "../../../models/filteringSensitivity";
import { RoleSeeAll } from "../roles/roles";
import Card from "./card/card";
import Popup from "./popup/popup";

const groupBy = function (xs: any, key: any) {
  return xs.reduce(function (rv: any, x: any) {
    (rv[x[key]] = rv[x[key]] || []).push(x);
    return rv;
  }, {});
};


export default function Groups(listOfApps: App[], searchInput: string, filteringProcesses: FilteringProcess[], filteringSensitivities: FilteringSensitivity[], logosByAppName: ({ [appName: string]: string | undefined }), role: string | null) {
  const [popupApp, setPopupApp] = useState<App | null>(null);
  const appsPerGroup = groupBy(listOfApps, 'category');
  const selectedProcessesIds: string[] = filteringProcesses.filter((p) => p.selected).map((p) => p.process.processId);
  const selectedSensitivities: AppSensitivity[] = filteringSensitivities.filter((s) => s.selected).map((p) => p.sensitivity);

  const groupsElements = Object.entries(appsPerGroup).map(([category, apps]: [string, any]) => {
    return (
      <div key={category as React.Key} className="flex flex-col my-4 max-w-full">
        <div className="text-black font-bold text-2xl text-center">
          {category}
        </div>
        <div className="flex flex-row flex-wrap max-w-full justify-center">
          {apps.map((app: App) => {
            // Apps can be filtered by Processes and Role
            if (searchInput === "" && selectedProcessesIds.length === 0 && selectedSensitivities.length === 0 && role === null) {
              return Card(app, logosByAppName[app.appName], () => setPopupApp(app));
            }

            // filter by SearchInput
            let showCardBySearchInput = searchInput.length === 0;
            if (showCardBySearchInput === false) {
              if (app.appName.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
                || app.shortDescription_fr.toLocaleLowerCase().includes(searchInput.toLocaleLowerCase())
              ) {
                showCardBySearchInput = true;
              }
            }

            // filter by Process
            let showCardByProcess = selectedProcessesIds.length === 0;
            if (showCardByProcess === false) {
              // at least one process of app is inside selectedProcesses
              for (let index = 0; index < app.processesIds.length; index++) {
                const appProcessId = app.processesIds[index];
                if (selectedProcessesIds.includes(appProcessId)) {
                  showCardByProcess = true;
                }
              }
            }

            // filter by Sensitivity
            let showCardBySensitivity = selectedSensitivities.length === 0;
            if (showCardBySensitivity === false) {
              // check if the sensitivity of the app is inside the selectedSensitivities
              if (selectedSensitivities.includes(app.sensitivity)) {
                showCardBySensitivity = true;
              }
            }

            // // filter by Role
            // let showCardByRole = role === RoleSeeAll;
            // // at least one role of app is selected Role
            // if (role !== null && app.roles.includes(role)) {
            //   showCardByRole = true;
            // }

            if (showCardBySearchInput === true && showCardByProcess === true && showCardBySensitivity === true) {
              // if (showCardByProcess === true && showCardBySensitivity === true && showCardByRole === true) {
              return Card(app, logosByAppName[app.appName], () => setPopupApp(app));
            }
          })}
        </div>
      </div>)
  });

  return (
    <div className="flex flex-col px-6 py-4">
      {Popup(popupApp, !!popupApp ? logosByAppName[popupApp?.appName] : undefined, () => setPopupApp(null))}
      {groupsElements}
    </div>
  )
}