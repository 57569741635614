

import TopBar from "./topBar"
import Main from "./apps/apps"

export default function Home() {
  return <div className="flex min-h-screen w-full flex-col bg-gray-50">
    <>
      {TopBar()}
      {Main()}
    </>
  </div>;
}