import { useEffect, useState } from "react";
import Loading from "../common/loading";
import Groups from "./groups/groups";
import Contact from "./contact";
import { App, AppSensitivity } from "../../models/app";
import ApplicationsListConfig from "../../config/applicationsList";
import Roles, { RoleSeeAll } from "./roles/roles";
import { FilteringProcess } from "../../models/filteringProcess";
import SearchBar from "./filters/searchBar";
import ProcessFilters from "./filters/processFilters";
import SensitivityFilters from "./filters/sensitivityFilters";
import { uniqueProcesses } from "../../lib/process";
import { FilteringSensitivity } from "../../models/filteringSensitivity";


const fetchHeaders = {
	method: 'GET',
	headers: {
		["PRIVATE-TOKEN"]: ApplicationsListConfig.gitlabGetToken,
	},
};

export default function Main() {
	const [role, setRole] = useState<string | null>(window.localStorage.getItem('DEEPLAB_HOMEPAGE_ROLE'));
	const [listOfRoles, setListOfRoles] = useState(new Set<string>());
	const [listOfApps, setListOfApps] = useState(new Array<App>());
	const [logosByAppName, setLogosByAppName] = useState<{ [appName: string]: string | undefined }>({});
	const [filteringProcesses, setFilteringProcesses] = useState(new Array<FilteringProcess>());
	const [filteringSensitivities, setFilteringSensitivities] = useState(new Array<FilteringSensitivity>());
	const [searchInput, setSearchInput] = useState<string>("");


	useEffect(() => {
		const fetchLogo = async (appName: string) => {
			const rawLogoDataQuery = await fetch(ApplicationsListConfig.logoUrl(appName),
				fetchHeaders);
			if (rawLogoDataQuery.status === 200) {
				const imageBlob = await rawLogoDataQuery.blob();
				const imageObjectURL = URL.createObjectURL(imageBlob);
				return { appName, logo: imageObjectURL };
			}
			return { appName, logo: undefined };
		};

		const fetchAndInitAppsAndTagsAndLogos = async () => {
			if (listOfApps.length === 0) {
				// load apps from Gitlab
				const rawListOfAppsQuery = await fetch(ApplicationsListConfig.fileUrl,
					fetchHeaders);
				let listOfApps: App[] = new Array<App>();
				if (rawListOfAppsQuery.status === 200) {
					listOfApps = await rawListOfAppsQuery.json();
					if (listOfApps.length > 0) {
						setListOfApps(listOfApps);
						// load logos for apps from Gitlab
						const logosByApp: { [appName: string]: string | undefined } = {};
						Promise.all(listOfApps.map((app) => fetchLogo(app.appName))).then((arrayLogosByApp) => {
							arrayLogosByApp.forEach((logoByAppName) => {
								logosByApp[logoByAppName.appName] = logoByAppName.logo;
							})
							setLogosByAppName(logosByApp);
						});
					}
				} else {
					alert("Il y a un problème de chargement des applications disponibles. Veuillez réessayer plus tard.")
				}

				if (listOfRoles.size === 0) {
					// handle roles
					const allRoles: string[] = listOfApps.filter(((app: App) => app.roles !== undefined)).flatMap((app: App) => app.roles);
					const uniqueRoles = new Set<string>(allRoles); // avoid duplicates
					uniqueRoles.add(RoleSeeAll); // Custom role to see all apps
					setListOfRoles(uniqueRoles);
				}
			}
		}

		fetchAndInitAppsAndTagsAndLogos();
	}, []);

	useEffect(() => {
		const initialProcessesState = (Array.from(uniqueProcesses)).map((process) => new FilteringProcess(process, false));
		setFilteringProcesses(initialProcessesState);
		const initialSensitivitiesState = [
			AppSensitivity.Contenu_sensible_autorisé,
			AppSensitivity.Contenu_sensible_à_protéger,
			AppSensitivity.Pas_de_contenu_sensible
		].map((sensitivity) => new FilteringSensitivity(sensitivity, false));
		setFilteringSensitivities(initialSensitivitiesState);
	}, []);

	const setRoleWrapper = (newRole: string) => {
		setRole(newRole);
		window.localStorage.setItem('DEEPLAB_HOMEPAGE_ROLE', newRole);
	}

	const chooseRoleComponent = Roles(listOfRoles, setRoleWrapper);
	const appsListComponent = (<>
		{/* removed from now as Process Filters are more rigorously defined {SelectRole(role || "", listOfRoles, setRoleWrapper)} */}
		{SearchBar(searchInput, setSearchInput)}
		{ProcessFilters(filteringProcesses, setFilteringProcesses)}
		{SensitivityFilters(filteringSensitivities, setFilteringSensitivities)}
		{Groups(listOfApps, searchInput, filteringProcesses, filteringSensitivities, logosByAppName, role)}
		{Contact()}
	</>);

	if (listOfApps.length === 0) {
		return <Loading />;
	}

	return (
		<div className="relative mb-6 mt-36">{/* mt-28 for the fixed TopBar */}
			{appsListComponent}
			{/* removed from now as Process Filters are more rigorously defined {role === null
				? chooseRoleComponent
				: appsListComponent
			} */}
		</div>

	);
}